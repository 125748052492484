.wd-title-wrapper {
  display: table;
  margin: 0 auto;
}
.wd-title-icon {
  margin-right: 10px !important;
  background: #fff !important;
  border-radius: 30px !important;
  padding: 5px !important;
}
.wd-title {
  font-size: 1.34rem !important;
  padding: 0 !important;
}
.wd-title-payment {
  color: #cccccc;
}
.wd-text-detail-label {
  margin-bottom: 20px !important;
}
.wd-text-detail-label-notes {
  margin-bottom: 10px !important;
}
.wd-payment-id {
  font-size: 0.84rem !important;
  padding-top: 3px !important;
}
.wd-notes {
  color: #a4a4a4 !important;
}
.wd-notes-empty {
  color: #cccccc !important;
  font-style: italic !important;
  text-align: right !important;
}
.wd-col-transactions {
  padding: 0 !important;
}
.wd-spacer-left {
  h4 {
    overflow: hidden;
  }
  h4:after {
    content:"";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid #e2e8ee;
  }
}
.wd-spacer-right {
  h4 {
    overflow: hidden;
  }
  h4:before {
    content:"";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 100%;
    margin-left: -100%;
    margin-right: 10px;
    border-top: 1px solid #e2e8ee;
  }
}
@include media-breakpoint-down(sm) {
  .wd-title .icon-sm {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .wd-title-line-break {
    display: inline-block !important;
  }
  .wd-col-transactions {
    padding: 0 15px !important;
  }
}
@include media-breakpoint-down(xs) {
  .wd-card {
    margin-top: 0 !important;
  }
  .wd-title {
    font-size: 1.2rem !important;
  }
  .wd-wrapper-detail {
    margin: 15px !important;
  }
  .wd-text-detail h4 {
    font-size: 1rem !important;
  }
  .wd-text-detail-label {
    margin-bottom: 5px !important;
  }
  .wd-payment-id {
    padding-top: 0 !important;
  }
  .wd-spacer-right {
    h4:before {
      width: 0 !important;
      margin-left: 15px !important;
      margin-right: 0 !important;
    }
  }
  .wd-col-notes {
    padding-right: 0 !important;
  }
}