//=======Buttons=======//
.onboarding-back-btn {
  position: absolute;
  top: 0;
  left: 20px;
}
.business-type-btn-wrapper {
  display: flex;
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(sm){
    flex-direction: column;
  }
}
.business-type-btn1,
.business-type-btn2 {
  width: 100%;
  color: #1d253b !important;
  padding: 25px !important;
  background: #fff !important;
  border-radius: 0.4285rem !important;
  border: 2px solid $primary-states !important;
  white-space: normal !important;
}
.business-type-btn1 {
  margin-right:20px !important;
}
.business-type-btn-wrapper .btn:hover,
.business-type-btn-wrapper .btn:focus,
.business-type-btn-wrapper .btn:active,
.business-type-btn-wrapper .btn.active,
.business-type-btn-wrapper .btn:active:focus,
.business-type-btn-wrapper .btn:active:hover,
.business-type-btn-wrapper .btn.active:focus,
.business-type-btn-wrapper .btn.active:hover {
  color: #8125D2 !important;
  background-image: none !important;
  background-color: #ffffff !important;
  border: 1px solid #8125D2 !important;
}
.business-type-description {
  color: $gray-600;
  font-size: 11px;
  font-weight: 400;
  margin: 5px 0 0 0;
}
//=======Selects=======//
.onboarding-wrapper .react-select .react-select__multi-value .react-select__multi-value__remove {
  border-left: none !important;
}
//=======Billing=======//
.billing-pad-wrapper {
  height: 335px;
  overflow-y: scroll;
}

.business-type-btn-custom {
  width: 100%;
  color: #1d253b !important;
  padding: 24px 24px !important;
  background: #fff !important;
  border-radius: 0.4285rem !important;
  border: 1px solid #cad1d7 !important;

    display: flex;
    flex-wrap: wrap;
  white-space: normal;

  .icon-text-wrapper{
    display: flex;
    flex-grow: 1;

    .btn-custom-icon{
      flex-direction: column;
      margin-right: 20px;
      color: $primary;
    }

    .btn-custom-text{
      h4 {
        text-align: left;
        margin: 0;
      }
      span {
        text-align: left;
        color: $muted;
        font-weight: $font-weight-base;
      }
    }
  }

  .beta-tag {
    position: absolute;
    top: 16px;
    right: -26px;
    transform: rotate(45deg) !important;
  }
}