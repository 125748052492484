.wda-action-icon {
  margin: 5px 5px 0 0 !important;
}
@include media-breakpoint-down(sm) {
  .wda3-col-action {
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #e2e8ee !important;
  }
}
@include media-breakpoint-down(xs) {
  .wda2-col-action {
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #e2e8ee !important;
  }
}