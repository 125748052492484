.StripeElement {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 15px;
  right: 15px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08) !important;
  border-radius: 5px;

  &.MgStripeElement {
    margin-bottom: 20px;
    &.invalid {
      margin-bottom: 5px;
    }
    
    input {
      font-family: Helvetica, sans-serif;
      font-size: 16px;
      border: none;
      padding: 0;
      line-height: 1.2em;
      height: 1.2em;

      &::placeholder {
        opacity: 1;
        color: #aab7c4;
      }

    }
  }

}
