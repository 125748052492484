.to-do {
  padding: 10px 0;
  margin: 0;

  Button {
    display: inline-block;
    float: right;
    padding: 7px 25px !important;
  }
}
