.register-ul-side-content{
  text-align: left;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  img {
    margin-right: 5px;
    line-height: 1px;
    margin-bottom: 3px;
  }

  li {
    margin-bottom: 10px;
  }

}
.text-primary-green {
  color: #D1F646 !important;
}
.auth-logo{
  width: 100px;
  @include media-breakpoint-down(sm) {
    display: none!important;
  }
}

.register-header {
  @include media-breakpoint-down(sm){
    font-size: $font-size-lg!important;
  }
}



.bill-stack {
 width: 10%;
  position: fixed;
  bottom: 10px;
  transform: rotate(-90deg);
  overflow: hidden!important;
}

.money-roll {
 width: 5%;
  position: fixed;
  bottom: 250px;
  right: 50px;
  transform: rotate(-45deg);
  overflow: hidden!important;
}

.credit-card {
  width: 10%;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(90deg);
  overflow: hidden!important;
}