.wd-card-timeline {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  overflow-y: scroll;
}
.wd-card-timeline-heading {
  margin-bottom: 3px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.wd-timeline-status {
  display: inline-block !important;
  color: #ffffff !important;
  font-size: small !important;
  padding:  5px 15px !important;
  border-radius: 20px !important;
  margin: -5px 0 5px 0 !important;
}
@include media-breakpoint-down(sm) {
  .wd-card-timeline {
    position: static !important;
    margin-top: 0 !important;
    max-height: 400px !important;
  }
}