body > #root > div {
  min-height: 100vh;
}

.pay-container-steps {
  padding: 50px 525px 50px 50px;
  height: 100%;
}


.login-page {
  .card-login {
    border-radius: $border-radius-lg;
    padding-bottom: $padding-base-horizontal;

    .card-header {

      overflow: hidden;

      .card-title {
        padding: 10px 10px 10px;
        text-align: center;
        position: relative;
        color: $black;
        z-index: 1;
      }
    }

    .btn-wd {
      min-width: 180px;
    }

    .logo-container {
      width: 65px;
      margin: 0 auto;
      margin-bottom: 55px;

      img {
        width: 100%;
      }
    }

    .input-group:last-child {
      margin-bottom: 40px;
    }

    &.card-plain {
      @include input-coloured-bg(
                      $opacity-5,
                      $white,
                      $white,
                      $transparent-bg,
                      $opacity-1,
                      $opacity-2
      );

      .input-group-addon,
      .form-group.no-border .input-group-addon,
      .input-group.no-border .input-group-addon {
        color: $opacity-8;
      }
    }

    &.card-white {
      background: $white;

      .card-header {
        .card-title {
          color: $black;
        }
      }

      @include form-control-placeholder(rgba($black, 0.4), 1);

      .has-danger {
        .form-control,
        .input-group-prepend .input-group-text {
          border-color: $danger-states;
        }
      }

      .input-group-prepend .input-group-text {
        border-color: rgba($black-states, 0.2);
        color: $black-states;
      }

      .form-control {
        color: $black;
        border-color: rgba($black-states, 0.2);

        &:focus {
          border-color: $primary;
        }
      }

      .form-group.no-border,
      .input-group.no-border {
        .form-control,
        .form-control + .input-group-prepend .input-group-text,
        .form-control + .input-group-append .input-group-text,
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {
          background-color: $opacity-gray-3;

          &:focus,
          &:active,
          &:active {
            background-color: $opacity-gray-5;
          }
        }

        .form-control {
          &:focus {
            & + .input-group-prepend .input-group-text,
            & + .input-group-append .input-group-text {
              background-color: $transparent-bg;
            }
          }
        }
      }

      .input-group[disabled] {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {
          background-color: $black;
        }
      }

      .form-control[disabled],
      .form-control[readonly],
      fieldset[disabled] .form-control {
        background: $light-gray;
        border-color: rgba($black-states, 0.3);
      }

      .input-group-focus {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text,
        .form-control {
          background-color: $white;
          border-color: $primary;
        }

        &.no-border {
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text {
            background-color: $opacity-gray-5;
          }
        }
      }

      .input-group-prepend .input-group-text {
        border-right: none;
      }

      .input-group-append .input-group-text {
        border-left: none;
      }

      .has-danger .form-control:focus,
      .has-success.input-group-focus .input-group-append .input-group-text,
      .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }

      .has-success .form-control:focus,
      .has-success.input-group-focus .input-group-append .input-group-text,
      .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: darken($success, 10%);
      }
    }
  }

  .link {
    font-size: 13px;
    text-decoration: none;
  }

  .card-black {
    .link {
      color: $white;
    }
  }
}

.full-page {
  & > .content,
  & > .footer {
    position: relative;
    z-index: 4;
  }

  & > .content {
    padding-bottom: 100px;
    padding-top: 150px;
    min-height: calc(100vh - 85px);
  }
  .footer {
    width: 100%;
    bottom: 0;
    padding-left: 48px;
    padding-right: 48px;

    .container {
      color: $white;
    }
  }

  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &.pricing-page {
    .description {
      margin-bottom: 65px;
      color: rgba($white, 0.7);
    }
  }

  &.register-page {
    .info-horizontal {
      padding: 0 0 20px;
    }

    .info-horizontal {
      text-align: left !important;

      .icon {
        margin-top: 0;

        > i {
          font-size: 2em;
        }

        &.icon-circle {
          width: 65px;
          height: 65px;
          max-width: 65px;
          margin-top: 8px;

          i {
            display: table;
            margin: 0 auto;
            line-height: 3.5;
            font-size: 1.9em;
          }
        }
      }

      .description {
        overflow: hidden;

        .info-title {
          margin-bottom: 20px;
        }
      }
    }
  }

  &.pricing-page,
  &.login-page,
  &.lock-page,
  &.register-page {
    min-height: 100vh;
    background: radial-gradient($background-black, $background-states-black);
  }
}

@include media-breakpoint-down(sm) {
  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .login-page .card-login .card-header img {
    width: 70%;
  }
}

@include media-breakpoint-down(xs) {
  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }
}

@media only screen and (max-device-width: 1300px) {
  .lock-page {
    .content {
      padding: 10px 30px 30px 30px;
    }
  }
}

@media only screen and (max-device-width: 1300px) {
  .lock-page {
    .navbar {
      display: none;
    }

    .content {
      padding: 5px 5px 5px 5px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .register-page .full-page .content {
    .card-register {
      margin-top: 3rem !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .lock-page,
  .login-page,
  .pricing-page,
  .register-page {
    .navbar {
      .navbar-wrapper .navbar-toggle .navbar-collapse {
        display: none !important;
      }
    }
  }
}

.basis-1 {
  flex-basis: 8.33333333%;
}

.basis-2 {
  flex-basis: 16.66666667%;
}

.basis-3 {
  flex-basis: 25%;
}

.basis-4 {
  flex-basis: 33.33333333%;
}

.basis-5 {
  flex-basis: 41.66666667%;
}

.basis-6 {
 flex-basis: 50%;
}

.basis-7 {
  flex-basis: 58.33333333%;
}

.basis-8 {
  flex-basis: 66.66666667%;
}

.basis-9 {
  flex-basis: 75%;
}

.basis-10 {
  flex-basis: 83.33333333%;
}

.basis-11 {
  flex-basis: 91.66666667%;
}

.basis-12 {
  flex-basis: 100%;
}

.new-payment-wrapper {
  @include media-breakpoint-up(md){
    display: flex;
    flex-flow: column;
    flex: 1 100%;
  }

  .new-payment-content{
    display: flex;
    flex: 1 100%;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin-bottom: 60px;
  }

  h2 {
    margin-bottom: 15px;
  }
  @include media-breakpoint-down(sm){
    h2 {
      font-size: 22px;
    }
  }
}

.new-payment-inner-content {
  width: 500px;
  margin: 0 auto;
  padding-top: 15px;
  @media (max-width: 530px) {
    width: 100% !important;
  }
}

.desktop{
  @include media-breakpoint-down(sm){
    display: none!important;
  }
}

.tablet {
  @include media-breakpoint-down(md){
    display: none!important;
  }
}

.mobile {
  @include media-breakpoint-up(md){
    display: none!important;
  }
}

.new-payment-contact-wrapper,
.new-payment-account-wrapper {
  position: relative;
}

.new-payment-new-contact-btn,
.new-payment-new-account-btn {
  font-weight: 600;
  position: absolute;
  top: -36px;
  right: 3px;
}

.remove-hover-style {
  a:hover {
    color: inherit !important;
  }
}