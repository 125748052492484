.card-user {
  overflow: hidden;

  .image {
    height: 120px;
  }

  .author {
    text-align: center;
    text-transform: none;
    margin-top: 25px;

    a + p.description {
      margin-top: -7px;
    }

    .block {
      position: absolute;
      height: 100px;
      width: 250px;

      &.block-one {
        @include linear-gradient-right(rgba($primary, 0.6), rgba($primary, 0));
        @include nc-rotate(150deg, 10);
        margin-top: -90px;
        margin-left: -50px;
      }

      &.block-two {
        @include linear-gradient-right(rgba($primary, 0.6), rgba($primary, 0));
        @include nc-rotate(30deg, 10);
        margin-top: -40px;
        margin-left: -100px;
      }

      &.block-three {
        @include linear-gradient-right(rgba($primary, 0.6), rgba($primary, 0));
        @include nc-rotate(170deg, 10);
        margin-top: -70px;
        right: -45px;
      }

      &.block-four {
        @include linear-gradient-right(rgba($primary, 0.6), rgba($primary, 0));
        @include nc-rotate(150deg, 10);
        margin-top: -25px;
        right: -45px;
      }
    }
  }

  .avatar {
    width: 124px;
    height: 124px;
    border: 5px solid lighten($gray-300, 5%);
    border-bottom-color: $transparent-bg;
    background-color: $transparent-bg;
    position: relative;
  }

  .card-body {
    min-height: 240px;
  }

  hr {
    margin: 5px 15px;
  }

  .button-container {
    margin-bottom: 6px;
    text-align: center;
  }

  .card-description {
    margin-top: 30px;
  }
}
