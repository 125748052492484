wsu-card-signup {
  margin-bottom: 30px !important;
}
@include media-breakpoint-down(sm) {
  .wsu-card-signup {
    margin-bottom: 15px !important;
  }
}
@include media-breakpoint-down(xs) {
  .wsu-row-cta {
    padding: 10px !important;
  }
  .wsu-col1-cta {
    padding-right: 0 !important;
    display: block !important;
  }
  .wsu-cta-logo {
    width: 40px !important;
    height: 40px !important;
    margin: 0 auto !important;
    float: none !important;
    display: block !important;
  }
  .wsu-cta-text {
    margin: 10px 0 !important;
    padding: 0 30px !important;
    display: block !important;
    text-align: center !important;
  }
}