.payment-wrapper {
  display: flex;
  flex-flow: column;
  flex: 1 100%;
  background-color: $light-bg;

  hr {
    margin-top: 0;
  }
  .rounded-top {
    border-radius: 16px 16px 0 0 !important;
  }
  .rounded-radial {
    position: relative;
    overflow: hidden;
  }
  .rounded-radial::before {
    content: '';
    position: absolute;
    left: -3.83%;
    right: -3.57%;
    top: -55.17%;
    bottom: -2.52%;
    background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 80%, rgba(209, 246, 70, 0.97) 110%) , #142B3A;
    filter: blur(28.9178px);
    border-radius: 9.25371px 0px 0px 9.25371px;
  }
  @include media-breakpoint-down(sm) {
    background-color: $white;
    .rounded-top {
      border-radius: 0 !important;
    }
  }
  .payment-navbar {
    background: $white;
    padding-left: 16px !important;
    padding-right: 0 !important;
    @include media-breakpoint-down(sm) {
      background: $default;
      color: $white;
      padding-left: 10px !important;
    }
    .navbar-items {
      justify-content: space-between;
      display: flex;
      flex-grow: 1;
      align-items: center;
      .help-logo-wrapper {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
          justify-content: space-between;
          width: 100%;
          flex-direction: row-reverse;
        }
        @include media-breakpoint-down(sm) {
          Button {
            margin-right: 10px !important;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
  .payment-content {
    display: flex;
    flex-direction: column;
    max-width: 720px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin: 0;
    }
    .container {
      max-width: 100% !important;
    }
    .container.payment-on-way-height {
      max-height: 425px;
    }
    .card {
      margin-top: 40px;
      @include media-breakpoint-up(md) {
        border-radius: 16px !important;
        background-color: $card-bg;
        background-clip: border-box;
        border: $card-border-width solid $card-border-color;
        @include border-radius($card-border-radius);
      }
      @include media-breakpoint-down(sm) {
        box-shadow: none;
        border: none !important;
        margin-top: 0 !important;
      }
    }
    .card-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .trust-footer {
      margin-top: 0 !important;
      margin-bottom: 40px !important;
    }
  }
  .payment-details-header {
    display: flex;
    align-items: center;
    margin: 0;
    background: $default;
    padding: 20px;
    border-radius: 16px 16px 0 0;
    h4 {
      color: $white;
    }
    @include media-breakpoint-down(sm) {
      height: 72px;
      border-radius: 0;
      padding: 15px;
    }
    .payment-details-header-logo {
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 52px;
      height: 52px;
      border: 4px solid $white;
      border-radius: 30px;
      -webkit-print-color-adjust: exact;

      @include media-breakpoint-down(sm) {
        width: 44px !important;
        height: 44px !important;
      }
    }
  }
  .method-btn {
    padding-left: 15px;
    padding-right: 15px;
  }
  .checkout-back-title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 34px 24px;
    @include media-breakpoint-down(sm) {
      padding: 34px 15px;
    }
  }
  .bank-connect-bg {
    position: relative;
    overflow: hidden;
  }
  .bank-connect-bg::before {
    content: '';
    position: absolute;
    left: -5.12%;
    right: -5.63%;
    top: -22.66%;
    bottom: -49.84%;
    background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #142B3A;
    filter: blur(73.3421px);
    border-radius: 23.4695px 0px 0px 23.4695px;
}
    .bank-connected-image {
      width: 100%;
    }
    .cloud-images-body {
      // position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      height: 20px;
      @include media-breakpoint-down(sm) {
        height: 40px;
      }
      @include media-breakpoint-down(xs) {
        height: 60px;
      }
      .bank-connect-cloud.cloud-images {
        // margin-top: 121px;
        bottom: -10px;
        @include media-breakpoint-down(sm) {
          height: 40px;
          bottom: 0px;
        }
        @include media-breakpoint-down(xs) {
          
        }
      }

      .cloud-images {
        position: relative;
        width: 100%;
        height: 100px;
        .cloud-2,
        .cloud-1 {
          position: absolute;
        }
        .cloud-1 {
          left: 0;
          top: 40px;
          width: 100px;
          top: 3px;
          width: 90px;
          @include media-breakpoint-down(sm) {
            max-width: 90px;
          }
          @include media-breakpoint-down(xs) {
            max-width: 55px;
          }
        }
        .cloud-2 {
          right: 0;
          top: 40px;
          max-width: 110px;
          @include media-breakpoint-down(sm) {
            max-width: 90px;
          }
          @include media-breakpoint-down(xs) {
            max-width: 55px;
          }
        }
      }
    }
    .cloud-images-body-absolute {
      position: absolute;
    }
    .bank-image img.bank-image-1 {
      max-width: 280px;
      margin-left: 40px;
      z-index: 1;
      position: relative;
      @include media-breakpoint-down(sm) {
        margin-left: 10px;
        max-width: 260px;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 10px;
        max-width: 220px;
      }
    }
    .bank-image .bank-image-2-body {
      position: absolute;
      overflow: hidden;
      right: 20px;
      bottom: 0;
      max-width: 650px;
      width: 100%;
      @include media-breakpoint-down(sm) {
        // max-width: 600px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 550px;
      }
    }

    .bank-image .bank-image-2-body img.bank-image-2 {
      max-width: 600px;
      margin-right: 60px;
      width: 100%;
      // position: absolute;
      // right: 60px;
      // bottom: 0;
      @include media-breakpoint-down(sm) {
        max-width: 600px;
        margin-right: 0;
      }
      @include media-breakpoint-down(xs) {
        max-width: 550px;
        margin-right: 0;
      }
    }
    .cloud-images-body.paymentSuccess {
      top: 190px;
    }

    .checkout-btn-group {
      margin-top: -30px;
      @include media-breakpoint-down(sm) {
        margin-top: -25px;
      }
      @include media-breakpoint-down(xs) {
        margin-top: -16px;
      }
    }
    .person {
      width: 30px;
      @include media-breakpoint-down(sm) {
        width: 25px;
      }
      @include media-breakpoint-down(xs) {
        width: 20px;
      }
    }
    .bg-radial-gradient {
      background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 87%, rgba(209, 246, 70, 0.97) 110%);
    }

  .connect-bank-image1 {
    // width: 160px;
    // position: absolute;
    // top: -24px;
    // left: 0;
    @include media-breakpoint-down(sm) {
      //width: 120px;
    }
  }
  .connect-bank-image2 {
    width: 130px;
    position: absolute;
    bottom: 30px;
    right: 0;
    @include media-breakpoint-down(sm) {
      width: 100px;
      bottom: 100px;
    }
  }
  .payment-secondary-action {
    padding-top: 24px;
    padding-bottom: 24px;
    margin: 0;
    text-align: center;
    background: $light-gray-bg;

    Button {
      padding: 14px 0 !important;
    }
  }
  .tracking-gradient {
    background: linear-gradient(77.55deg, #8125D2 3.23%, #142B3A 91.75%);
  }
  .bank-method-wrapper {
    padding: 0 24px;
    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }
  }
  .payment-info {
    //background: $light-gray-bg;
    padding: 0 9px;
    h4 {
      margin: 16px 0 10px 0;
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray !important;
      margin-bottom: 15px;
      display: inline-block;
    }
    p {
      float: right;
      font-size: 16px;
      font-weight: 500;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    @include media-breakpoint-down(xs) {
      h5 {
        font-size: 0.75rem !important;
        display: block;
        margin-bottom: 0;
      }
      p {
        float: left;
        margin-bottom: 20px;
      }
    }
  }
  .checkout-spacer {
    padding: 0 9px;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  .recurring-history-wrapper {
    height: 446px;
    overflow: scroll;
  }
}
.debitSuccess-body.bg-payment-on-way:before {
  background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 116%), #142B3A;
}

.debitSuccess-body.bg-payment-on-way:after {
  background-size: cover;
}
.cloud-images-body.debitSuccess.cloud-images-body-absolute {
  top: 110px;
}

div.debitbody-img-1 {
  max-width: 270px;
  margin: 0 auto;
  z-index: 4;
  position: relative;
}
img.debitbody-img-2 {
  max-width: 510px;
  z-index: 2;
  position: relative;
  right: -15px;
  margin-top: -160px;
  margin-bottom: 15px;
  float: right;
  width: 100vw;
}
.debitbody-img-2 .debitbody-img-2-gap {
  width: 20vh;
}

@media(max-width: 745px) {
  img.debitbody-img-2 {
    float: none;
    margin-left: 23vw !important;
    max-width: 550px;
  }
}
// TODO:might require refactoring
@media(max-width: 426px) {
  img.debitbody-img-2 {
    float: none;
    margin-left: 13vw !important;
    max-width: 550px;
    margin-top: -180px;
  }
  div.debitbody-img-1 {
    max-width: 220px;
    margin: 0 auto;
    z-index: 4;
    position: relative;
    margin-bottom: 63px;
  }
}
//TODO: might require refactoring
@media(max-width: 380px) {
  div.debitbody-img-1 {
    max-width: 170px;
    margin: 0 auto;
    z-index: 4;
    position: relative;
    margin-bottom: 63px;
  }
  img.debitbody-img-2 {
    float: none;
    margin-left: 11vw !important;
    max-width: 550px;
    margin-top: -180px;
  }

}

.greenfoot-message {
  display: block !important;
  font: small/1.5 Arial,Helvetica,sans-serif !important;
  font-weight: bold !important;
  font-size: large !important;
  color: #FF0000 !important;
  text-align: center !important;
}

.banner-text {
  color: #6c757d  !important;
}

.success-heading {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 30px;
  @include media-breakpoint-down(sm) {
    margin-top: 0px;
    font-size: 20px;
  }
  @include media-breakpoint-down(xs) {
    margin-top: 0px;
    font-size: 15px;
  }
}

.bg-payment-on-way {
  position: relative;
  overflow: hidden;
}
.bg-payment-on-way:before {
  content: '';
  position: absolute;
  left: -5.12%;
  right: -5.67%;
  top: -16.39%;
  bottom: -8.7%;
  background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #142B3A;
  filter: blur(29.2991px);
  border-radius: 9.37571px 0px 0px 9.37571px;
}
.bg-payment-on-way:after {
  content: '';
  background-image: url('../../../img/Pattern.png');
  height: 40%;
  width: 100%;
  position: absolute;
  background-position: bottom;
  bottom: 0;
  background-size: contain;
}

.bank-connect-heading {
  position: relative; 
}
.bankstop {
  margin-top: -90px;
}
.bankstop,
.banksbottom {
  max-width: 250px;
}
img.ml-auto.mr-auto.d-block.banksbottom {
  max-width: 300px;
  position: relative;
  // bottom: -70px;
}
.bg-paymentSuccess {
  background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%), #142B3A;
}
.z-index-1 {
  z-index: 1 !important;
}

@media(max-width: 426px) {
  .bank-image img.bank-image-1 {
    max-width: 170px !important;
  }
}

@media(max-width: 380px) {
  .bank-image img.bank-image-1 {
    margin-left: 10px;
    max-width: 150px !important;
  }
}