.alert {
  border: 0;
  color: $white;

  .alert-link {
    color: $white;
  }

  &.alert-success {
    background-color: darken($success, 10%);
  }

  i.fa,
  i.tim-icons {
    font-size: $font-paragraph;
  }

  .close {
    color: $white;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
  }

  span[data-notify="icon"] {
    font-size: 22px;
    display: block;
    left: 19px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
  }

  button.close {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -13px;
    width: 25px;
    height: 25px;
    padding: 3px;
  }

  .close ~ span {
    display: block;
    max-width: 89%;
  }

  &.alert-with-icon {
    padding-left: 65px;
  }
}

.alert-dismissible {
  .close {
    top: 50%;
    right: $alert-padding-x;
    padding: 0;
    transform: translateY(-50%);
    color: rgba($white, 0.6);
    opacity: 1;

    &:hover,
    &:focus {
      color: rgba($white, 0.9);
      opacity: 1 !important;
    }

    @include media-breakpoint-down(xs) {
      top: 1rem;
      right: 0.5rem;
    }

    & > span:not(.sr-only) {
      font-size: 1.5rem;
      background-color: transparent;
      color: rgba($white, 0.6);
    }

    &:hover,
    &:focus {
      & > span:not(.sr-only) {
        background-color: transparent;
        color: rgba($white, 0.9);
      }
    }
  }
}

.custom-toast {
  min-height: 50px;
  min-width: 256px;
  font-size: $font-size-lg;
}

@include media-breakpoint-down(lg) {
  .verification-banner {
    margin-left: 30px !important;
  }
}
@include media-breakpoint-down(sm) {
  .verification-banner {
    margin: 0 15px !important;
  }
}

// Announcement Banner
.announcement-banner-wrapper {
  padding: 0 20px;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(sm) {
  padding: 0 15px;
  }
}
.announcement-banner {
  margin: 20px auto 0 auto;

  p {
    color: $muted !important;
    margin: 0;
  }

  h3 {
    margin-bottom: 0.25rem;
    color: $black !important
  }

  h4 {
    color: $primary !important;
    margin: 0;
  }

  button.close {
    top: 22px;
    color: $default !important;
  }

  img {
    width: 250px;
    height: 130px;
    float: left;
    margin-right: 15px;
  }

  span {
    display: block;
  }

  @include media-breakpoint-down(md) {
    span {
      display: inline;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 15px;
    h4 {
      margin: 0;
    }
    h3{
      font-size: $font-size-lg;
    }

    img {
      display: none;
    }
  }
}