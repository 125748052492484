.tim-row {
    margin-bottom: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}

.typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}

.typography-line span {
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    position: absolute;
    width: 260px;
    text-transform: none;
}

.tim-row {
    padding-top: 60px;
}

.tim-row h3 {
    margin-top: 0;
}

.offline-doc .page-header:before {
    content: "";
    background: rgba(0, 0, 0, .75);
}

.offline-doc .page-header {
    display: flex;
    align-items: center;
}

.offline-doc .footer {
    position: absolute;
    width: 100%;
    background: transparent;
    bottom: 0;
    color: #fff;
    z-index: 1;
}

#map {
    position: relative;
    width: 100%;
    height: 100vh;
}