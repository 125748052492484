.modal {
  &.fade .modal-dialog {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: show;
  }

  &.show.modal-long .modal-dialog {
    transform: translateY(10%);
  }
}
