/*** iPhone and iOS Form Input Zoom Fixes ***/
@include media-breakpoint-down(sm) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px!important; }
}


/* Form controls */
@include form-control-placeholder(#6c757c, 1);

.form-control {
  border-color: lighten($black, 5%);
  border-radius: $border-radius-lg;
  font-size: $font-size-sm;
  @include transition-input-focus-color();

  &:focus {
    border-color: $primary;
    background-color: $input-bg;
    @include box-shadow(none);

    & + .input-group-append .input-group-text,
    & ~ .input-group-append .input-group-text,
    & + .input-group-prepend .input-group-text,
    & ~ .input-group-prepend .input-group-text {
      border: 1px solid $primary;
      border-left: none;
      background-color: $transparent-bg;
    }
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus {
    @include box-shadow(none);
  }

  .has-danger &,
  .has-success & {
    &.form-control-success,
    &.form-control-danger {
      background-image: none;
    }
  }

  & + .form-control-feedback {
    border-radius: $border-radius-lg;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }

  .open & {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    border-bottom-color: $transparent-bg;
  }

  & + .input-group-append .input-group-text,
  & + .input-group-prepend .input-group-text {
    background-color: $white;
  }
}

.has-success .input-group-append .input-group-text,
.has-success .input-group-prepend .input-group-text,
.has-success .form-control {
  border-color: lighten($black, 5%);
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-append .input-group-text,
.has-success.input-group-focus .input-group-prepend .input-group-text {
  border-color: darken($success, 10%);
}

.has-danger .form-control,
.has-danger .input-group-append .input-group-text,
.has-danger .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-append .input-group-text {
  border-color: lighten($danger-states, 5%);
  color: $danger-states;
  background-color: rgba(222, 222, 222, 0.1);

  &:focus {
    background-color: $transparent-bg;
  }
}

.has-success,
.has-danger {
  &:after {
    font-family: "nucleo";
    content: "\ea1b" !important;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 13px;
    color: $success !important;
    font-size: 11px;
  }

  &.form-control-lg {
    &:after {
      font-size: 13px;
      top: 24px;
    }
  }

  &.has-label {
    &:after {
      top: 37px;
    }
  }

  &.form-check:after {
    display: none !important;
  }

  &.form-check .form-check-label {
    color: $success;
  }
}

.has-danger {
  &:after {
    content: "\ea48" !important;
    color: $danger-states !important;
  }

  &.form-check .form-check-label {
    color: $danger-states;
  }
}

@include media-breakpoint-down(xs) {
  .form-horizontal .col-form-label,
  .form-horizontal .label-on-right {
    text-align: inherit;
    padding-top: 0;

    code {
      padding: 0 10px;
    }
  }
}

.review-payment {
  border: 0.0625rem solid #e9ecef !important;
  box-shadow: 0 0.125rem 0.25rem rgba(38, 39, 48, 0.075) !important;
  padding: 1rem;
  border-radius: $border-radius;
  margin-bottom: 1rem;


  h3, h5 {
    margin-bottom: 0;
    font-weight: 500;
  }
  span {
    font-size: 0.75rem !important;
    color: $dark-gray !important;
    font-weight: $font-weight-bold;
  }

}

.white-form {
  text-align: left !important;
  .form-control {
    color: $white!important;
  }

  label {
    color: $white !important;
  }

  .react-select {
    *{
      border-color: $white!important;
      color: $white!important;
      background-color: $default!important;
      border-radius: $border-radius!important;
    }
  }
  .react-select__value-container{
    color: $white!important;
  }

  input {
    border-color: $white!important;
    height: 49px !important;
  }
}

.radio-form {
  font-size: 0.75rem;
}