.onboarding-wrapper {
  @include media-breakpoint-up(md){
    display: flex;
    flex-flow: row;
  }

  & > * {
    padding: 0 0 32px 0;
    flex: 1 100%;
  }

  .onboarding-progress-wrapper {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .onboarding-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 3rem;
  }
  .auth-side-content{
    z-index: 1;
    @include media-breakpoint-down(sm){
      display: none !important;
    }
  }
}
.auth-wrapper {
  display: flex;
  flex-flow: row wrap;

  & > * {
    flex: 1 100%;
  }

  .auth-content {
    padding: 2rem;
    background-color: $white;
    z-index: 2;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .content-aligner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-aligner-item {
    flex: 1;
    padding-bottom: 80px;
  }

  .auth-side-content{
    z-index: 1;
    @include media-breakpoint-down(sm){
      display: none !important;
    }
  }

  .bg-image-singup::before {
    content: '';
    background-image: url('../../../../img/Pattern.png');
    height: 76%;
    width: 100%;
    position: absolute;
    background-position: bottom;
  }
  .bg-image-singup-bottom.bg-image-singup {
    position: absolute !important;
    bottom: -3vh;
    left: 0;
    right: 0;
    max-width: 30vw;
    margin: 0 auto;
  }

  .bg-signup {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .bg-signup::before {
    content: '';
    background: radial-gradient(77.1% 118.77% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%), #142B3A;
    position: absolute;
    height: 100%;
    z-index: -1;
    left: -100px;
    right: -100px;
  }

  .onboarding-progress-wrapper {
    max-width: 260px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.onboarding-footer {
  @include media-breakpoint-down(sm){
    display: none!important;
  }
}

.bank-security{
  width: 100%;
  overflow: no-display;
  position: relative;
  margin-top: 25%;
  padding: 2rem;
}

.code-form-side-content {
  width: 100%;
  padding: 2rem;
  align-self: flex-end;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.auth-logo-small {
  width: 20px;
  margin-right: 12px;
  float: left;
}

.key-login {
  height: 71vh;
  height: 100%;
  max-height: 550px;
  overflow: hidden;
  position: relative;
}
.login-bg {
  position: absolute;
  width: 767.3px;
  height: 1374.47px;
  left: 1445.7px;
  top: -60px;
  background: radial-gradient(61.38% 98.47% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%)

}

.key-login img.img-1,
.key-login img.img-2 {
  position: absolute;
  margin: auto;
  // max-width: 42vw;
  // height: 100%;
  // margin-left: -20px;
  top: 10px;
  width: 50vw;
  transition: 1s;
  max-width: unset;
  left: -8vw;
}
.key-login img.img-1 {
  // margin-left: -120px;
  // max-width: 50vw;
}

.key-login img.img-1 {
  opacity: 0;
}
// .key-login:hover img.img-1 {
//   opacity: 1;
// }
// .key-login:hover img.img-2 {
//   opacity: 0;
// }

.key-login:after {
  // content: '';
  // position: absolute;
  // width: 767.3px;
  // height: 1374.47px;
  // left: 345.7px;
  // top: -60px;
  // background: radial-gradient(61.38% 98.47% at 50% 3.64%, rgba(76, 53, 134, 0) 42.71%, rgba(186, 117, 247, 0.34) 75%, rgba(209, 246, 70, 0.97) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  // filter: blur(50px);
  // border-radius: 16px 0px 0px 16px;
}

.radial-background {
  background-image: url('../../../../img/updated_bg.png');
  background-size: 100% 100%;
  height: 100%;
}

.radial-background.radial-background-hover {
  background-image: url('../../../../img/updated_bg_2.png');
}

@media(min-width: 768px ) {
  .key-login {
    max-height: 450px;
  }

  .key-login img.img-1,
  .key-login img.img-2 {
    top: unset;
    width: 38vw;
    left: -4vw;
    bottom: 20vh;
  } 
}

@media(min-width: 1023px ) {
  .key-login {
    max-height: 500px;
    max-height: calc(320px + 40vh);
  }

  .key-login img.img-1,
  .key-login img.img-2 {
    top: 15vh;
    width: 44vw;
    left: -4vw;
  }
}

@media(min-width: 1200px) and (max-width: 1440px ) {
  .key-login {
    // max-height: 530px;
  }

  .key-login img.img-1,
  .key-login img.img-2 {
    top: unset;
    width: 50vw;
    left: -8vw;
    bottom: 14vh;
  }
}

@media(min-width: 1440px ) {

  .key-login {
    // max-height: 550px;
  }

  .key-login img.img-1,
  .key-login img.img-2 {
    width: 45vw;
    left: -4vw;
  }
}

// 
@media(max-width: 800px ) {
  .heading-sm {
    font-size: inherit;
  }
  .sub-heading-sm {
    font-size: 0.6rem;
    padding-left: 0;
  }
  .sub-heading-sm li span {
    font-size: 0.6rem;
  }
}
@media(min-width: 1600px ) {

  .key-login {
    max-height: 80vh;
  }

  .key-login img.img-1,
  .key-login img.img-2 {
    top: unset;
    width: 45vw;
    left: -3vw;
    bottom: 6vh;
  }

  .heading-big-4 { 
    font-size: 3.3rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .sub-heading-big-4 {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.2;
  }
}