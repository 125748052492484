img {
  max-width: 100%;
  border-radius: $border-radius-sm;
}

.img-raised {
  box-shadow: $box-shadow-raised;
}

.company-logo {
  position: absolute;
  top: .5%;
  right: .5%;
  display: block;
  height: 5rem;
}

@media (max-device-width: 500px) {
  .company-logo {
    visibility: hidden;
  }
}


