.outline-none {
  outline: 0;
  box-shadow: none !important;
  cursor: pointer;
}


.table {
  > tbody > tr > td {
    color: $dark !important;

    .photo {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  > tbody > tr.table-success > td {
    background-color: darken($success, 10%);
  }

  > tbody > tr.table-info > td {
    background-color: $info;
  }

  > tbody > tr.table-primary > td {
    background-color: $primary;
  }

  > tbody > tr.table-warning > td {
    background-color: $warning;
  }

  > tbody > tr.table-danger > td {
    background-color: $danger;
  }

  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;
    margin-top: 5px;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  > thead > tr > th {
    border-bottom-width: 1px;
    border-top-width: 1px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border: 0;
    color: rgba($white, 0.7);
  }


  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border-color: rgba(255, 255, 255, 0.1);
    padding: 8px;
    vertical-align: middle;
  }

  &.table-shopping tbody tr:last-child td {
    border: none;
  }

  .th-description {
    max-width: 150px;
  }

  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }

  .td-total {
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }

  > tfoot > tr {
    color: hsla(0, 0%, 100%, 0.7);
    text-transform: uppercase;
  }
}

.table-shopping {
  > thead > tr > th {
    text-transform: uppercase;
  }

  > tbody > tr > td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }

  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;

    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }

  .td-number {
    font-weight: $font-weight-light;

    .btn-group {
      padding-right: 15px;
    }
  }

  .td-name {
    min-width: 200px;
  }

  .td-number {
    text-align: right;
    min-width: 170px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

.table-responsive {
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

table.tablesorter thead tr .header {
  background-image: url("../../../img/bg.gif");
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}

table.tablesorter thead tr .headerSortUp {
  background-image: url("../../../img/asc.gif");
}

table.tablesorter thead tr .headerSortDown {
  background-image: url("../../../img/desc.gif");
}

// datatables

.dataTables_wrapper {
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .form-control-sm {
    font-size: 10px;
  }
}

.mh-700 {
  min-height: 700px;
}

.table-actions-row {
  padding: 16px 20px 16px 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.date-col {
  width: 150px !important;
}

.account-col {
  width: 20% !important;
}

.status-col {
  width: 100px !important;
  max-width: 150px !important;
}

.amount-col {
  width: 150px !important;
  text-align: right;
  max-width: 150px !important;
}

.action-col {
  width: 50px !important;
}

.bottom-border {
  border-bottom: 1px solid $light-gray;
}

.table-headers {
  @include media-breakpoint-down(sm){
    display: none;
  }
  min-width: 140px;
  &.flex-column {
    border-right: 1px solid $light-gray;
  }
  li {
    a {
      font-weight: $font-weight-bold;
      color: $gray-600;
    }
    &.active {
      *{

      }
      border-bottom: 2px solid $primary;
    }
  }
}
.user-table-header {
  @include media-breakpoint-down(sm){
    display: block !important;
  }
  min-width: 50px;
  &.flex-column {
    border-right: 1px solid $light-gray;
  }
}

.mobile-tab-nav {
  @include media-breakpoint-up(md){
    display: none!important;
  }
  li {
    a {
      font-weight: $font-weight-normal;
      color: $gray-500;
    }
  }
  i {
    color: $gray-600!important;
    font-weight: $font-weight-extra-bold!important;
  }
}

.transaction-table {
  th {
    &:last-child {
      text-align: right;
    }
  }

}

.table-min-h {
  min-height: 500px!important;
}

.contact-tags-table {
  height: 325px !important;
  width: 100% !important;
  overflow-y: scroll !important;
  display: block !important;
  th {
    width: 100% !important;
  }
}

.import-bills-table {
  padding: 0 !important;
  td {
    padding: 14px 7px !important;
  }
  tr:hover {
    background: transparent !important;
  }
  textarea.form-control {
    max-height: 100px !important;
  }
  textarea {
    height: 38px !important;
    padding: 0.6rem 0.7rem !important;
    transition: all 0.3s ease;
    line-height: 1.5 !important;
  }
  textarea:focus {
    height: 100px !important;
    overflow: visible !important;
  }
  .deposit-account-select {
    width: 100%;
  }
  .react-select__indicators {
    width: 30px !important;
  }
  input[name="amountDue"] {
    width: 100px;
    padding-right: 0 !important;
  }
}
.review-bills-table {
  padding: 0 !important;
  tr:hover {
    background: transparent !important;
  }
}