
.breadcrumb-wizard {
  list-style: none;
  overflow: hidden;
  margin-bottom: 16px;
  //@include media-breakpoint-down(sm){
  //  display: none;
  //}
}
.breadcrumb-wizard .wizard-item {
  float: left;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}
.breadcrumb-wizard .wizard-item .wizard-text {
  color: white;
  text-decoration: none;
  height: 48px;
  padding: 0.75rem 0 0.9375rem 50px;
  background: $white; /* fallback color */
  position: relative;
  display: block;
  float: left;
  font-weight: 600;
  font-size: $font-size-sm;
  pointer-events: none;
  box-sizing: border-box;
  width: 100%;
  //@include media-breakpoint-down(sm){
  //  display: none;
  //}


  .wizard-number {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    background-color: $gray-600;
    color: $white;
    padding: 3px;
  }

}
.breadcrumb-wizard .wizard-item .wizard-text::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid $white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb-wizard .wizard-item .wizard-text::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid $gray-300;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb-wizard .wizard-item:first-child .wizard-text {
  padding-left: 10px;
}

/* Colors */
.active-1 {
  .wizard-item:nth-child(1) .wizard-text {background-color: $primary; color: $white; }
  .wizard-item:nth-child(1) .wizard-text:after { border-left-color: $primary; }
  .wizard-item:nth-child(1) .wizard-text .wizard-number {background-color: $white; color: $primary; }

  .wizard-item:nth-child(2) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(2) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(3) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(3) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(4) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(4) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(5) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(5) .wizard-text:after { border-left-color: $white; }

}

.active-2 {
  .wizard-item:nth-child(1) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(1) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(1) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(2) .wizard-text {background-color: $primary; color: $white }
  .wizard-item:nth-child(2) .wizard-text:after { border-left-color: $primary; }
  .wizard-item:nth-child(2) .wizard-text .wizard-number {background-color: $white; color: $primary; }

  .wizard-item:nth-child(3) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(3) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(4) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(4) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(5) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(5) .wizard-text:after { border-left-color: $white; }
}

.active-3 {
  .wizard-item:nth-child(1) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(1) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(1) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(2) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(2) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(2) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(3) .wizard-text {background-color: $primary; color: $white }
  .wizard-item:nth-child(3) .wizard-text:after { border-left-color: $primary; }
  .wizard-item:nth-child(3) .wizard-text .wizard-number {background-color: $white; color: $primary; }

  .wizard-item:nth-child(4) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(4) .wizard-text:after { border-left-color: $white; }

  .wizard-item:nth-child(5) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(5) .wizard-text:after { border-left-color: $white; }
}

.active-4 {
  .wizard-item:nth-child(1) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(1) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(1) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(2) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(2) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(2) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(3) .wizard-text {background-color: $black; color: $white; }
  .wizard-item:nth-child(3) .wizard-text:after { border-left-color: $black; }
  .wizard-item:nth-child(3) .wizard-text .wizard-number {background-color: $primary; color: $white; }

  .wizard-item:nth-child(4) .wizard-text {background-color: $primary; color: $white }
  .wizard-item:nth-child(4) .wizard-text:after { border-left-color: $primary; }
  .wizard-item:nth-child(4) .wizard-text .wizard-number {background-color: $white; color: $primary; }

  .wizard-item:nth-child(5) .wizard-text {background-color: $white; color: $gray-600 }
  .wizard-item:nth-child(5) .wizard-text:after { border-left-color: $white; }
}

  .breadcrumb-wizard .wizard-item:first-child .wizard-text {
    padding-left: 25px;
    padding-right: 0;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

.breadcrumb-wizard .wizard-item:last-child .wizard-text {
  padding-right: 44px;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

  .breadcrumb-wizard .wizard-item:last-child .wizard-text::after {
    border: 0;
    visibility: hidden;
  }
.breadcrumb-wizard .wizard-item:last-child .wizard-text::before {
  border: 0;
  visibility: hidden;
}

