.details-close-btn {
  font-size: 1.375rem;
  float: right;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 4px;

  svg {
    margin-bottom: 0;
  }
}

.details-add-btn, .details-edit-button {
  margin-top: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  float: right;
  font-size: 1.375rem;
}

.details-action-wrapper {
  float: right;
  border-right: 1px solid $gray-200;
  height: 35px;

  Button,
  .details-more-btn {
    font-size: 1.375rem;
    float: right;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
  }

  svg {
    margin-bottom: 0;
  }
}

.details-mobile-actions {
  display: none;
}

@include media-breakpoint-down(sm) {

  .details-desktop-actions {
    display: none;
  }

  .details-mobile-actions {
    display: block;
  }
}

.information-row {
  margin: 0;
  padding: 20px 0;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;

  .information-wrapper {
    display: flex;
    flex-grow: 1;
  }

  .information-icon {
    flex-direction: column;
  }

  .information-icon  img {
    width: 40px;
  }

  svg {
    font-size: 30px !important;
    margin: 7px 20px 0 5px !important;
    color: $primary;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 0;
  }

  span {
    color: $gray-600;
  }
}

.information-row:hover {
  background: $row-highlight;
}