.card-wizard {
  min-height: 500px;
  background-color: $white;
}

@include media-breakpoint-down(sm){
  .only-desktop{
    visibility: hidden;
  }
}


.border-radius{
  border-radius: $border-radius;
}

.terms-list {
  li {
    margin-top: 32px;
  }
}

.terms-check-box {
  vertical-align: top;
}

.review-summary-box {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $black;
  text-align: center;
  > h1, h2 {
    color: $white;
    margin-bottom: 0;
  }
  > h6 {
    color: $white;
    font-size: 10px;
  }
}

.review-summary-box-white {
  background-color: $white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  > h6 {
    color: $gray-600;
    font-size: 10px;
  }
  > p {
    font-size: 12px;
  }
}

.wizard-info-col{
  @include media-breakpoint-down(md){
    display: none!important;
  }
}

.wizard-sidebar-toggler{
  @include media-breakpoint-up(lg){
    display: none!important;
  }
}

.wizard-form-field{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}