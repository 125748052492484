.wf-footer-icon-wrapper {
  margin: 0 auto !important;
  display: inline-block !important;
}
.wf-footer-icon {
  font-size: 18px !important;
  color: #6c757d !important;
  margin: 0 18px !important;
}
@include media-breakpoint-down(xs) {
  .wf-trust-wrapper {
    padding: 0 !important;
  }
}