.badge {
  & + .badge {
    margin-left: 4px;
  }
}

.badge-notification {
  color: $danger;
  background-color: transparent;
  padding: 0;
  margin: 0;
  height: 6px;
  width: 6px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  top: 10px;
  right: 10px;
}
