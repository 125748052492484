.w-checkout-wrapper {
  margin: 0 50px;
}
.w-auth-col {
  padding: 0 60px;
}
.w-auth-title {
  margin-top: 30px;
}
.w-auth-type-title {
  margin: 5px 0 10px 0;
}
.w-checkout-auth-btn {
  margin: 10px 0 50px 0;
}
.w-checkout-footer-btn-unlisted {
  margin-right: 10px;
}
.w-checkout-bank-success-wrapper {
  width: 100%;
  padding-top: 40px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wd-checkout-container {
  max-width: 100% !important;
}

@include media-breakpoint-down(lg) {
  .w-checkout-wrapper {
    margin: 0;
  }
}
@include media-breakpoint-down(md) {
  .w-auth-title {
    font-size: 1.4rem;
    margin-top: 15px;
  }
}
@include media-breakpoint-down(sm) {
  .wizard-step-name {
    display: none;
  }
  .w-wrapper .payment-page {
    padding: 15px 0 0 0;
  }
  .w-checkout-wrapper .card .card-body {
    padding-bottom: 0;
  }
  .w-manual-bank-form-col {
    padding: 0 20px;
  }
  .terms-check-box {
    margin: 0 0 10px 0 !important;
  }
}
@include media-breakpoint-down(xs) {
  .w-auth-col {
    padding: 0 15px;
  }
  .w-auth-title {
    font-size: 1.2rem;
    margin: 10px 0 20px 0;
  }
  .w-checkout-bank-success-title {
    font-size: 1.6rem;
  }
  .w-checkout-auth-btn {
    margin-bottom: 30px;
  }
  .w-checkout-footer-btn,
  .w-checkout-footer-btn-unlisted {
    display: block;
    width: 100%;
  }
  .w-checkout-footer-btn-unlisted {
    margin: 10px 0 0 0;
  }
}