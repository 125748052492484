.card.card-timeline {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .timeline {
    list-style: none;
    padding: 0 0 20px;
    position: relative;

    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: $black;
      left: 50%;
      margin-left: -1.5px;
    }

    .timeline-footer {
      .btn {
        margin: 0;

        &.dropdown-toggle {
          i {
            top: -1px;
            left: 10px;
          }

          &:after {
            margin-left: 20px !important;
          }
        }
      }
    }

    h6 {
      color: rgba($white, 0.8);
      font-weight: 400;
      margin: 10px 0px 0px;
    }

    &.timeline-simple {
      &:before {
        left: 5%;
      }

      > li > .timeline-panel {
        width: 86%;
      }

      > li > .timeline-badge {
        left: 5%;
      }
    }

    > li {
      margin-bottom: 20px;
      position: relative;

      &:before,
      &:after {
        content: " ";
        display: table;
      }

      &:after {
        clear: both;
      }
    }

    > li > .timeline-panel {
      background: $card-black-background;
      width: 45%;
      float: left;
      padding: 20px;
      border-radius: $border-radius-sm;
      box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
      color: $black;
      margin-bottom: 20px;
      position: relative;

      &:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid $black;
        border-right: 0 solid $black;
        border-bottom: 15px solid transparent;
        content: " ";
      }

      &:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid $card-black-background;
        border-right: 0 solid $card-black-background;
        border-bottom: 14px solid transparent;
        content: " ";
      }

      &.timeline-panel-white {
        background: $white;

        .timeline-body p {
          color: $black-states;
        }

        &:after,
        &:before {
          border-right-color: $white;
          border-left-color: $white;
        }
      }
    }

    > li > .timeline-badge {
      color: $white;
      width: 50px;
      height: 50px;
      line-height: 51px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -25px;
      background-color: $dark-gray;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;

      [class^="ti-"],
      [class*=" ti-"] {
        line-height: inherit;
      }

      .tim-icons {
        width: 25px;
        height: 21px;
        text-align: center;
      }
    }

    > li.timeline-inverted > .timeline-panel {
      float: right;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }

  .timeline-heading {
    margin-bottom: 15px;
  }

  .timeline-badge.primary {
    background-color: $primary !important;
  }

  .timeline-badge.default {
    background-color: $default !important;
  }

  .timeline-badge.info {
    background-color: $info !important;
  }

  .timeline-badge.success {
    background-color: darken($success, 10%) !important;
  }

  .timeline-badge.warning {
    background-color: $warning !important;
  }

  .timeline-badge.danger {
    background-color: $danger !important;
  }

  .timeline-title {
    margin-top: 0;
    color: inherit;
  }

  .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
    color: $white;
  }

  .timeline-body > p + p {
    margin-top: 5px;
  }
}

@include media-breakpoint-down(sm) {
  .card.card-timeline {
    .timeline {
      &:before {
        left: 5%;
      }

      > li > .timeline-badge {
        left: 5%;
      }

      > li > .timeline-panel {
        float: right;
        width: 83% !important;

        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }

        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }
}
