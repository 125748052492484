.notification-row {
  margin: 0;
  padding: 15px 0 10px 0;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;

  .notification-wrapper {
    display: flex;
    flex-grow: 1;
  }

  .notification-icon {
    flex-direction: column;
  }

  i {
    font-size: 18px;
    margin: 5px 20px 0 5px !important;
    color: $primary;
  }
}

.notification-row:hover {
  background: $row-highlight;
}

.notification-btn {
  position: relative !important;
}

.notification-count {
  position: absolute !important;
  top: 6px !important;
  right: 4px !important;
  background: $warning;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  color: $white;
  font-size: 10px;
  font-weight: 600;
  padding-top: 1px;
}