.tab-selector{
  background-color: $light-bg;
  display: inline-block;
  border-radius: $border-radius;
  margin-left: 15px;
  margin-right: auto;
  padding: 2px;
  .tab-item{
    display: inline-block;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 12px;
    font-weight: $font-weight-bold;
    &.active{
      background-color: $white;
      color: $primary;


    }

  }
}
