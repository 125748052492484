.add-bank-modal-wrapper {
  max-width: 1000px !important;
  padding: 15px;
  margin-top: 0 !important;
}
.add-bank-modal-header {
  height: 35px;
  padding-right: 5px;
  margin-bottom: 25px;
}
.add-bank-modal-body {
  padding: 0 20px 35px 25px;
}
.add-bank-wrapper {
  margin: 0 !important;
}
.add-bank-tab-col {
  padding-right: 20px !important;
}
.add-bank-tab-wrapper {
  margin: 0 !important;
  min-height: 581px !important;
}
.MuiTab-root {
  max-width: 50% !important;
  min-width: 50% !important;
  padding:  10px 12px 6px 12px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #8125D2 !important;
  font-weight: 600 !important;
}
.MuiTabs-indicator {
  background-color: #8125D2 !important;
}
.add-bank-flinks-loader {
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.add-bank-flinks-wrapper {
  padding: 0;
  height: 510px;
  position: relative;
  overflow-y: hidden;
}
.add-bank-flinks {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.add-bank-title {
  font-weight: 600;
  text-align: center;
  margin: 0 0 30px 0;
}
.add-bank-methods-wrapper {
  margin-top: 30px;
  padding: 0 20px 0 0;
}

.hide-on-mobile{
  @include media-breakpoint-down(sm){
    display: none;
  }
}
.add-bank-method {
  text-align: left !important;
  @include media-breakpoint-down(sm){
    display: none;
  }
}
.add-bank-method-icon-wrapper {
  width: 22px;
  height: 22px;
  margin-right: 12px;
  float: left;
}
.add-bank-method-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
  padding-top: 2px;
}
.add-bank-method-description-wrapper {
  margin: 0 0 0 35px;
}
.or {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #d6d6d8;
    line-height: 0.1em;
    margin-bottom: 25px;
  }
.or span {
    background:#fff;
    padding:0 10px;
  }
.add-bank-method-description {
  font-size: 13px;
  line-height: 1.7;
}
.add-bank-trust-wrapper {
  @include media-breakpoint-down(sm){
    display: none;
  }
  margin:  25px 20px 0 20px;
  padding: 10px 0;
  border: 2px solid #f5f5f5;
  border-radius: 6px;
  line-height: 1.8;
}
.add-bank-encrypted-text {
  font-weight: 600;
  font-size: 11px;
  color: #8e959b !important;
}
.add-bank-trust-text {
  font-size: 11px;
  padding: 0 15px;
  color: #8e959b !important;
}
.add-bank-trust-link {
  color: #8e959b !important;
  font-weight: 500;
}
.add-bank-trust-wrapper:hover {
  .add-bank-trust-link {
    color: #8125D2 !important;
  }
}
//===========Manual===========//
.manual-bank-form-wrapper {
  padding: 15px 0 15px 15px;
}
.manual-bank-form-wrapper .form-group {
  margin-bottom: 7px !important;
}
.manual-bank-form-col {
  padding: 0 15px 0 0;
}
.manual-bank-cheque {
  width: 100%;
  padding: 0 90px 10px 75px;
  display: block;
  margin: 0 auto;
  max-width: 600px;
}
.manual-bank-input,
.manual-bank-form-wrapper .react-select .react-select__control,
.css-yk16xz-control {
  border-radius: 0.4285rem !important;
  min-height: 35px !important;
}
.manual-bank-form-wrapper .form-group .form-control {
  padding: 10px 8px !important;
}
.manual-bank-form-label {
  color: #525f7f !important;
  font-size: 11px !important;
  letter-spacing: 0.2px !important;
}
.manual-bank-form-button-wrapper {
  margin: 10px 15px 0 0;
}