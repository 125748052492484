.modal-overlay {
  &.modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: none!important;
  }
  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    padding: 10px;
  }

  .overlay-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px 30px 15px;
  }

  .nav {
    margin: 0 20px;
    a {
      font-weight: $font-weight-bold;
      padding: 16px 0;
      cursor: pointer!important;
    }

  }
}

.pop-out-overlay {
  @include media-breakpoint-up(lg){
    .MuiDialog-paperFullScreen {
      margin-left: 58%;
    }
  }
  @include media-breakpoint-only(md){
    .MuiDialog-paperFullScreen {
      margin-left: 45%;
    }
  }
}

.hoc-class {
  display: none;
  @media print {
    display: block;
  }
}