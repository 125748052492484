
.sidebar-wizard{
  background-color: $white;
  height: 100%;
  z-index: 5!important;
  width: 85%;
  padding: 25px 25px 25px 25px;
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.sidebar-wizard-wrapper{
  height: 100%;
  width: 256px;
  z-index: 5!important;
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.sidebar-wizard-content{
  @include media-breakpoint-up(lg){
    display: none;
  }

}
.sidebar-wizard-overlay{
  z-index: 4!important;
  @include media-breakpoint-up(lg){
    visibility: hidden!important;
  }
}

.sidebar-wizard-root{
  @include media-breakpoint-up(lg){
   display: none;
  }
  top: 56px!important;
}