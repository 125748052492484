.hide-unless-print {
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }
  .hide-on-print * {
    display: none;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .payment-content {
      max-width: 100% !important;
      justify-content: normal !important;
    }
    .hide-unless-print {
      display: block;
    }
  }
}